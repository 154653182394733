import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"
import ServicesAccordion from "../components/Accordion/ServicesAccordion"
import "../styles/automation-and-intelligence.scss"
import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "../components/ContactForm"
import LogoCarousel from "../components/LogoCarousel"

//Images
import Divider from "../images/Blue-line.png"
import MapImg from "../images/map.png"
import { CgSmartphoneChip } from "react-icons/cg"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import { SiHomeassistant } from "react-icons/si"
import { ImCogs } from "react-icons/im"
import { GoDashboard } from "react-icons/go"
import TextAccordion from "../components/TextAccordion/TextAccordion"

//data for accordion

//Workflow Automation
const panels1 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Modern digital workflows unlock productivity for employees and enterprises. Metsi has many years of experience in workflow automation across self-service portals, IT service catalogues and security orchestration. We are experts in automating multi-step processes that occur between systems to help companies achieve better business outcomes. We design and deliver the processes that make your business more effective and efficient, with end-to-end automation that mitigates bottlenecks.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "One of our workflow automation solutions is IT-as-a-Service (ITaaS) creation, delivery, and assurance. Metsi’s ITaaS engineers create a unified self-service IT portal experience that increases user satisfaction in interacting with and receiving services from the IT organisation. By enabling ITaaS creation and delivery, Metsi facilitates cross departmental automation that is standardised and repeatable. The time and effort taken to deliver services is greatly reduced, and a consistent services experience is assured. Metsi has also built workflow automation tools for complex migration services.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Our approach to workflow automation analyses the logical architecture, user interface, targets, activities and adapters, variables, workflow inputs and outputs, triggers and tasks and user interaction. Whether we are delivering workflow services for ITaaS or security orchestration, we map variables between activities and workflows, and leverage PowerShell and Python scripts where needed. We implement data processing using table variables, logic constructs and conditional statements.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//Network Automation
const panels2 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Metsi builds dynamic, programmatically efficient network and infrastructure configurations to improve performance and monitoring, making infrastructure management more like cloud computing than traditional IT management. We specialise in network automation through Infrastructure as Code (IaC) and network as Code (NaC) to deliver the most agile infrastructure.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "By treating infrastructure and networking like software, Metsi’s IaC approach addresses the three key issues of manual infrastructure: high price, slow installs and environment consistencies. Here, network automation creates a foundation that can be leveraged for automation throughout the IT enterprise and attain continuous digital innovation.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Instead of configuring each component separately by running a script, Metsi’s engineers create software files that define consistent ways of provisioning, configuring, and deploying networking and infrastructure across multiple environments. We take a traditional network spanning thousands of devices on different platforms and OS versions and use environment playbooks across multiple fabric controllers to deliver robust network automation.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//Cloud Transformation
const panels3 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Metsi’s specialist cloud team designs, delivers and optimises hybrid multicloud environments in order to consume cloud services in a consistent way. We specialise in cloud management and orchestration, workload management and orchestration, and container management. Metsi’s cloud migration services enable you to re-platform and refactor applications for your hybrid multicloud environment, and then optimise your cloud operations.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "With Metsi, you can optimise your public cloud strategy and transform existing on-premises applications in a flexible mix-and-match model that supports your business objectives. We focus on improving total cost of ownership to drive optimal ROI, providing assurance for governance and compliance as you transform, and leveraging cloud native services in pursuit of ability and business impact. Our next generation architectures allow enterprises to simplify business processes through workflow automation and seamless integration, analyse deployed workloads for visibility into the total cloud spend, and identify cost-optimisation strategies.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Our architects have simplified the dependencies of numerous multi-tier applications into a single application profile by leveraging numerous out-of-the-box and custom integrations in the customers’ existing infrastructure ecosystem. Applications are then delivered consistently and reliably across private and public clouds in a manner that eases the transition to operations teams, while adhering to a pre-established governance framework consisting of application and infrastructure policies.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//XaaS
const panels4 = [
  {
    icon: "",
    title: "Summary",
    content:
      "We build custom IaaS, PaaS, and DRaaS (Disaster Recovery as a Service) solutions for our customers and partners. Because we are highly focused on IT automation, orchestration and integration across the IT stack, we are able to leverage the best of breed in closed and open-source technologies to truly deliver the often elusive “single pane of glass” XaaS experience. Metsi covers all aspects of the IaaS and PaaS journey to multicloud maturity, such as readiness assessments; a crawl-walk-run approach to XaaS delivery; and formal release engineering and application lifecycle for ongoing managed services.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Many customers start with IaaS, which helps abstract their applications from the underlying hardware and begin their PaaS journey to add an extra layer of abstraction to the underlying operating system so they can focus entirely on the business logic of their applications. Wherever you are in your XaaS journey, Metsi will take the next step toward future transformation with you. Our streamlined approach to PaaS maturity uses data-driven insights to drive continuous improvement, automate platform and infrastructure optimisation, and build security and compliance into the service lifecycle.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Metsi has proven its XaaS capabilities at scale by designing and delivering a custom PaaS solution that modernises US defence systems and allows them to achieve 600% faster application deployment times through an end-to-end multi domain IT stack with Cisco. This PaaS solution provides a custom Metsi analytics dashboard, full stack visibility, automated application performance, and end user provisioning and consumption on a secure, scalable, and fault-tolerant abstracted architecture.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

const AutomationAndIntelligence = () => {
  //gatsby-image query
  const automationData = useStaticQuery(graphql`
    query automationImages {
      hero: file(relativePath: { eq: "leaf.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bridge: file(relativePath: { eq: "bridge.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      lake: file(relativePath: { eq: "lake.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      forest: file(relativePath: { eq: "forest-2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Service Orchestration"
        description="Metsi’s service orchestration empowers the software defined data centre. Our service orchestration services include workflow and network automation, cloud transformation, XaaS, infrastructure as a service (iaas) and platform as a service (paas)."
        keywords={[
          "it infrastructure services",
          "it infrastructure management services",
          "Cloud computing and service oriented architecture",
          "xaas services",
          "everything as a service",
          "anything as a service xaas",
          "xaas",
        ]}
        lang="en-gb"
      />

      {/********* Hero Section ***********/}
      <BackgroundImage
        fluid={automationData.hero.childImageSharp.fluid}
        className="automation-hero"
        style={{
          width: "100vw",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="row">
                <div className="inner-container-2 left">
                  <div className="droplet-blue">
                    <CgSmartphoneChip className="icon" />
                  </div>
                </div>
                <div className="inner-container-2 right">
                  <div className="column">
                    <h1>Service Orchestration</h1>
                    <img src={Divider} alt="divider" className="divider" />
                    <p style={{ color: "#626366" }}>
                      Modern workload placement is not only about moving to the
                      cloud, but also about creating a baseline for
                      infrastructure strategy based on workloads rather than
                      physical data centre. Where organisations have difficulty
                      in understanding the best fit destination for their
                      workloads lies in the highly automated and disciplined
                      approach required to performs a thorough, metrics-based
                      analysis of existing workloads. Metsi empowers customers
                      to migrate workloads optimally, while reducing risk
                      through minimum modification to workloads.
                    </p>
                    {/* <ButtonB href="#services">Learn More</ButtonB> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/********* Services ***********/}
      <div className="section services" id="services">
        <div className="inner-container">
          <h2>Service Orchestration</h2>
          <h3>Empowering the Software Defined Data Centre</h3>
          <p>
            “By 2025, 85% of infrastructure strategies will integrate
            on-premises, colocation, cloud and edge delivery options, compared
            with 20% in 2020.”
            <br />
            – Gartner Research Report
            <br />
            “Your Data Center May Not Be Dead, but It’s Morphing,” September 17,
            2020
          </p>
        </div>
        <div className="row" style={{ maxWidth: "1400px" }}>
          <div className="inner-container-3" style={{ maxWidth: "180px" }}>
            <a href="#workflow_automation">
              <div className="droplet-blue">
                <SiHomeassistant className="icon" />
              </div>
            </a>
            <a href="#workflow_automation">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                Workflow Automation
              </h2>
            </a>
          </div>
          <div className="inner-container-3">
            <a href="#network_automation">
              <div className="droplet-blue">
                <ImCogs className="icon" />
              </div>
            </a>
            <a href="#network_automation">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                Network Automation
              </h2>
            </a>
          </div>
          <div className="inner-container-3">
            <a href="#cloud_transformation">
              <div className="droplet-blue">
                <GoDashboard className="icon" />
              </div>
            </a>
            <a href="#cloud_transformation">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                Cloud Transformation
              </h2>
            </a>
          </div>
          <div className="inner-container-3">
            <a href="#xaas_">
              <div className="droplet-blue">
                <SiHomeassistant className="icon" />
              </div>
            </a>
            <a href="#xaas_">
              <h2
                className="droplet-header"
                style={{ fontSize: "22px", color: "#000" }}
              >
                XaaS
              </h2>
            </a>
          </div>
        </div>
      </div>

      {/********* Workflow Automation ***********/}
      <BackgroundImage
        fluid={automationData.bridge.childImageSharp.fluid}
        className="section"
        id="workflow_automation"
        style={{
          width: "100vw",
          minHeight: "80vh",
          backgroundPosition: "top",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Service Orchestration</h2>
              <h3>Workflow Automation</h3>
            </div>
          </div>
          <TextAccordion panels={panels1} />
        </div>
      </BackgroundImage>

      {/*********** Network Automation *************/}
      <div className="section" id="network_automation">
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Service Orchestration</h2>
              <h3>Network Automation</h3>
            </div>
          </div>
          <TextAccordion panels={panels2} />
        </div>
      </div>
      {/*********** Cloud Transformation *************/}
      <BackgroundImage
        fluid={automationData.lake.childImageSharp.fluid}
        className="section"
        id="cloud_transformation"
        style={{
          width: "100vw",
          minHeight: "70vh",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Service Orchestration</h2>
              <h3>Cloud Transformation</h3>
            </div>
          </div>
          <TextAccordion panels={panels3} />
        </div>
      </BackgroundImage>

      {/********* XaaS ***********/}
      <BackgroundImage
        fluid={automationData.forest.childImageSharp.fluid}
        className="section"
        id="xaas_"
        style={{
          width: "100vw",
          minHeight: "80vh",
          backgroundPosition: "top",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#000",
        }}
      >
        <div
          className="overlay section"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="outer-container">
            <div className="inner-container">
              <h2>Service Orchestration</h2>
              <h3>XaaS: Anything as a Service</h3>
            </div>
          </div>
          <TextAccordion panels={panels4} />
        </div>
      </BackgroundImage>

      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1 style={{ textAlign: "center" }}>
                Have a question about our solutions?
              </h1>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AutomationAndIntelligence
